<template>
  <div id="resetPassword">
    <h2 style="margin-bottom: 16px; text-align: center">重置密码</h2>

    <a-form
      :model="form"
      :style="{ width: '480px', margin: '0 auto' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item
        field="userPassword"
        tooltip="密码不小于八位"
        label="密码"
        :rules ="[{required:true,message:'不能为空'},{minLength:8,message:'密码不小于八位'}]"
        :validate-trigger="[`change`,`input`]"
      >
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item
        field="checkPassword"
        tooltip="确认密码不小于八位"
        label="确认密码"
        :rules ="[{required:true,message:'不能为空'},{validator: (value, cb) => {
            if (value !== form.userPassword) {
              cb('两次密码不一致')
            } else {
              cb()
            }
          }}]"
        :validate-trigger="[`change`,`input`]"
      >
        <a-input-password
          v-model="form.checkPassword"
          placeholder="请确认密码"
        />
      </a-form-item>
      <a-form-item>
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
          "
        >
          <a-button type="primary" html-type="submit" style="width: 120px">
            提交
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { defineProps, reactive, ref, withDefaults } from "vue";
import API from "@/api";
import {
  getLoginUserUsingGet,
  updateMyUserUsingPost,
  userLoginUsingPost
} from "@/api/userController";
import { useLoginUser } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import PictureUploader from "@/components/PictureUploader.vue";

const loginUserStore = useLoginUser();
const router = useRouter();

interface Props {
  id: string;
}
const props = withDefaults(defineProps<Props>(), {
  id: () => {
    return "";
  },
});

const form = ref({
  userPassword: "",
  checkPassword: "",
} as API.UserUpdateMyRequest);


/**
 * 提交
 */
const handleSubmit = async () => {
  console.log(form);
  const res = await updateMyUserUsingPost(form.value);
  if (res.data.code === 0) {
    await loginUserStore.fetchLoginUser();
    message.success("更新成功");
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("更新失败" + res.data.message);
  }
};

const rules = {
  userPassword: [
    { required: true, message: "请输入密码", trigger: "blur" },
    {
      min: 8,
      message: "密码不小于八位",
      trigger: "blur",
    },
  ],
  checkPassword: [
    { required: true, message: "请输入确认密码", trigger: "blur" },
    {
      min: 8,
      message: "确认密码不小于八位",
      trigger: "blur",
    }
  ]
}
</script>
