<template>
  <a-form
    :model="formSearchParams"
    :style="{ marginBottom: '20px' }"
    layout="inline"
    @submit="doSearch"
  >
    <a-form-item filed="userName" label="用户名">
      <a-input
        allow-clear
        v-model="formSearchParams.userName"
        placeholder="请输入用户名"
      />
    </a-form-item>
    <a-form-item filed="userProfile" label="用户简介">
      <a-input
        allow-clear
        v-model="formSearchParams.userProfile"
        placeholder="请输入用简介"
      />
    </a-form-item>
    <a-form-item label="查询">
      <a-button type="primary" html-type="submit" style="width: 120px"
        >搜索
      </a-button>
    </a-form-item>
  </a-form>
  <a-table
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      total,
    }"
    @page-change="onPageChange"
  >
    <template #userAvatar="{ record }">
      <a-image width="64" :src="record.userAvatar" />
    </template>
    <template #createTime="{ record }">
      {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button status="danger" @click="doDelete(record)">删除</a-button>
        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
      </a-space>
      <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel">
        <template #title> 修改用户权限 </template>
        <div>
          <a-radio-group v-model="form.userRole">
            <a-radio value="user">普通用户</a-radio>
            <a-radio value="admin">管理员</a-radio>
          </a-radio-group>
        </div>
      </a-modal>
    </template>
  </a-table>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import {
  deleteUserUsingPost,
  listUserByPageUsingPost,
  updateUserUsingPost,
} from "@/api/userController";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import Api from "@/api";

// 初始搜索条件
const initSearchParams = {
  current: 1,
  pageSize: 10,
};

const formSearchParams = ref<API.UserQueryRequest>({});
const searchParams = ref<API.UserQueryRequest>({
  ...initSearchParams,
});
const dataList = ref<API.User[]>([]);
const total = ref<number>(0);

/**
 * 加载数据
 */
const loadData = async () => {
  const res = await listUserByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败" + res.data.message);
  }
};

/**
 * 监听钩子
 */
watchEffect(() => {
  loadData();
});

/**
 * 分页变化时改变搜索条件并重新加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 删除
 * @param record
 */
const doDelete = async (record: API.User) => {
  if (!record.id) {
    return;
  }
  const res = await deleteUserUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除数据失败" + res.data.message);
  }
};

const visible = ref(false);
const currentRecord = ref<API.User | null>(null); // 保存当前行的数据

const handleClick = () => {
  visible.value = true;
};

const handleEdit = (record: API.User) => {
  currentRecord.value = record;
  visible.value = true;
};

const handleOk = async () => {
  if (!currentRecord.value?.id) {
    return;
  }
  const res = await updateUserUsingPost({
    id: currentRecord.value.id,
    userRole: form.value.userRole,
  });
  if (res.data.data) {
    message.success("修改成功");
    visible.value = false;
    loadData();
  } else {
    message.error("修改失败" + res.data.message);
  }
};

const handleCancel = () => {
  visible.value = false;
};

const form = ref({
  userRole: "user",
});

/**
 * 执行搜索功能
 */
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "账号",
    dataIndex: "userAccount",
  },
  {
    title: "用户名",
    dataIndex: "userName",
  },
  {
    title: "用户头像",
    dataIndex: "userAvatar",
    slotName: "userAvatar",
  },
  {
    title: "用户简介",
    dataIndex: "userProfile",
  },
  {
    title: "用户权限",
    dataIndex: "userRole",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>
