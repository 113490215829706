<template>
  <a-card class="appCard" hoverable @click="doCardClick">
    <template #actions>
      <!--      <span class="icon-hover"> <IconThumbUp /> </span>-->
      <span class="icon-hover" @click="doShare"> <IconShareInternal /> </span>
    </template>
    <template #cover>
      <div
        :style="{
          height: '204px',
          overflow: 'hidden',
        }"
      >
        <img
          :style="{ width: '100%', transform: 'translateY(-20px)' }"
          :alt="props.app.appName"
          :src="props.app.appIcon"
        />
      </div>
    </template>
    <a-card-meta :title="props.app.appName" :description="props.app.appDesc">
      <template #avatar>
        <div
          :style="{ display: 'flex', alignItems: 'center', color: '#1D2129' }"
        >
          <a-avatar
            :size="24"
            :image-url="props.app.user?.userAvatar"
            :style="{ marginRight: '8px' }"
          />
          <a-typography-text
            >{{ props.app.user?.userName ?? "匿名用户" }}
          </a-typography-text>
        </div>
      </template>
    </a-card-meta>
  </a-card>
  <ShareModle :link="shareLink" title="应用分享" ref="shareModelRef" />
</template>

<script setup lang="ts">
import {
  IconThumbUp,
  IconShareInternal,
  IconMore,
} from "@arco-design/web-vue/es/icon";
import App from "@/App.vue";
import { useRouter } from "vue-router";
import ShareModle from "@/components/ShareModle.vue";
import { ref } from "vue";

interface Props {
  app: API.AppVO;
}

const props = withDefaults(defineProps<Props>(), {
  app: () => {
    return {};
  },
});
const shareModelRef = ref();

const doShare = (e: Event) => {
  if (shareModelRef.value) {
    shareModelRef.value.openModel();
  }
  //组织冒泡，防止一点击就跳转详情页
  e.stopPropagation();
};
//分享链接拼接
const shareLink = `${window.location.protocol}//${window.location.host}/app/detail/${props.app.id}`;

const router = useRouter();
const doCardClick = () => {
  router.push(`/app/detail/${props.app.id}`);
};
</script>
<style scoped>
.icon-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.1s;
}

.appCard {
  cursor: pointer;
}

.icon-hover:hover {
  background-color: rgb(var(--gray-2));
}
</style>
