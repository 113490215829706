import { defineStore } from "pinia";
import { computed, ref } from "vue";
//@ts-ignore
import api from "@/api";
import { getLoginUserUsingGet } from "@/api/userController";
import AccessEnum from "@/access/accessEnum";
import ACCESS_ENUM from "@/access/accessEnum";

/**
 * 用于管理登录用户状态的Pinia store
 */
export const useLoginUser = defineStore("loginUser", () => {
  /**
   * 存储当前登录用户的信息，初始状态为未登录
   */
  const loginUser = ref<API.LoginUserVO>({
    userName: "未登录",
  });

  /**
   * 设置新的登录用户信息
   * @param newLoginUser 新的登录用户对象
   */
  function setLoginUser(newLoginUser: API.LoginUserVO) {
    loginUser.value = newLoginUser;
  }

  /**
   * 异步获取登录用户信息
   * 从后端API获取登录用户信息，并更新store中的用户状态
   */
  async function fetchLoginUser() {
    const res = await getLoginUserUsingGet();
    if (res.data.code === 0 && res.data.data) {
      loginUser.value = res.data.data;
    } else {
      loginUser.value = { userRole: ACCESS_ENUM.NOT_LOGIN };
    }
  }

  // 返回store中的状态和方法
  return { loginUser, setLoginUser, fetchLoginUser };
});
