<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>
    <template v-else>
      <Basiclayout />
    </template>
  </div>
</template>
<script setup lang="ts">
import Basiclayout from "@/layout/BasicLayout.vue";

import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import PictureUploader from "@/components/PictureUploader.vue";

const route = useRoute();
/**
 * 全局初始化函数，有全局单次调用的代码，都可以写到这里
 */
const doInit = () => {
  console.log("hello 欢迎来到我的项目");
};

onMounted(() => {
  doInit();
});
</script>
<style></style>
