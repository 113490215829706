<template>
  <a-modal v-model:visible="visible" :footer="false" @cancel="handleCancel">
    <template>
      {{ title }}
    </template>
    <h4 style="margin-top: 0">复制分享链接</h4>
    <a-typography-paragraph copyable>{{ link }}</a-typography-paragraph>
    <h4>手机扫码查看</h4>
    <img :src="codeImg" />
  </a-modal>
</template>

<script setup lang="ts">
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { defineProps, ref, withDefaults } from "vue";
import QRCode from "qrcode";
import message from "@arco-design/web-vue/es/message";

/**
 * 定义组件属性类型
 */
interface Props {
  //分享链接
  link: string;
  //弹窗标题
  title: string;
}

const codeImg = ref();
/**
 * 给组件指定初始值
 */
const props = withDefaults(defineProps<Props>(), {
  link: () => "http://",
  title: () => "分享",
});
//是否可见
const visible = ref(false);

const openModel = () => {
  visible.value = true;
};

const handleCancel = () => {
  visible.value = false;
};

defineExpose({
  openModel
})

QRCode.toDataURL(props.link)
  .then((url: any) => {
    codeImg.value = url;
  })
  .catch((err: any) => {
    message.error("二维码生成失败" + err.message);
  });
</script>

<style scoped></style>
