<template>
  <div id="updateMyPage">
    <h2 style="margin-bottom: 16px; text-align: center;">更新信息</h2>

    <a-form
      :model="form"
      :style="{ width: '480px', margin: '0 auto' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item field="userName" label="设置用户名">
          <a-input v-model = "form.userName" placeholder="请输入用户名"/>
      </a-form-item>
      <a-form-item field="userAvatar" label="上传用户头像">
        <PictureUploader
          biz="user_avatar"
          :value="form.userAvatar"
          :onChange="(url) => (form.userAvatar = url)"
        />
      </a-form-item>
      <a-form-item field="post"  label="用户简介">
        <a-textarea v-model="form.userProfile" placeholder="输入用户简介" allow-clear/>
      </a-form-item>
      <a-form-item>
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
          "
        >
          <a-button type="primary" html-type="submit" style="width: 120px"
          > 提交
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, watchEffect } from "vue";
import API from "@/api";
import { getLoginUserUsingGet, updateMyUserUsingPost, userLoginUsingPost } from "@/api/userController";
import { useLoginUser } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import PictureUploader from "@/components/PictureUploader.vue";

const loginUserStore = useLoginUser();
const router = useRouter();


const form = ref({
  userName: "",
  userAvatar: "",
  userProfile: "",
} as API.UserUpdateMyRequest);
/**
 * 提交
 */
const handleSubmit = async () => {
  console.log(form);
  const res = await updateMyUserUsingPost(form.value);
  if (res.data.code === 0) {
    await loginUserStore.fetchLoginUser();
    message.success("更新成功");
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("更新失败" + res.data.message);
  }
};
const loadData = async ()=>{
  const res = await getLoginUserUsingGet();
  if(res.data.code === 0){
    form.value=res.data.data as API.UserUpdateMyRequest;
  }
}
watchEffect(() => {
  loadData();
});
</script>
